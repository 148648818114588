.contact_container {
  padding: 4rem 0rem;
  background: aliceblue;
}

.form_container {
  padding-left: 80px;
  padding-right: 40px;
}

@media (max-width: 1088px) {
  .form_container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
