* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
body {
  overflow-x: hidden;
  overflow-y: auto;
}

:root {
  --primary: #fff;
  --dark: #000;
  --hover_color: #3385ff;
  --secondary: #f6f8fa;
  --heading: #2f2f2f;
  --para: #818181;
}
