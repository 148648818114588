.awards_container img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

.grid-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-wrapper > div > img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.grid-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.grid-wrapper p {
  text-align: center;
  margin: 1em;
}

.awards_item {
  position: relative;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 230px;
  width: 310px;
}

.awards_item:last-child:nth-child(3n - 2) {
  grid-column-end: -2;
}

.awards_item-hover {
  background-color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  font-size: large;

  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 6px;
  transition: 1s;
}
.awards_item-hover:hover {
  opacity: 1;
}

.awards_container {
  padding-top: 4rem;
  background-color: #fff;
}

.awards_item-hover p {
  color: #fff;
  font-size: 18px;
}

.titlepage {
  text-align: center;
  padding-bottom: 30px;
}

.titlepage h2 {
  font-size: 40px;
  color: #3e3e3e;
  line-height: 57px;
  font-weight: bold;
  padding: 0;
  padding-bottom: 20px;
}
