.counter {
  background-color: var(--secondary);
  padding: 20px 0;
  border-radius: 5px;
}

.count-title {
  font-size: 40px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.count-text {
  font-size: 13px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.fa-2x {
  margin: 0 auto;
  float: none;
  display: table;
  color: var(--hover_color);
}
.numbers {
  display: flex;
}

@media screen and (max-width: 700px) {
  .numbers {
    flex-direction: column;
  }
}
