.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 150px 50px;
  background-color: #f9f9f9;
}

.hero-content {
  margin: 0 0 50px;
  text-align: center;
}
.hero-content p,
h1 {
  color: #f9f9f9;
}

.hero-heading {
  font-size: 48px;
  font-weight: semi-bold;
  margin-bottom: 20px;
}

.hero-paragraph {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.hero-image {
  width: 10%;
  max-width: 800px;
}

.hero-image img {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

@media only screen and (max-width: 768px) {
  .hero-image {
    width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .hero-image {
    width: 300px;
  }
  .hero-image img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
  .laptop .laptop__bottom {
    height: 0.2rem;
  }
}

@media only screen and (min-width: 768px) {
  .hero-section {
    flex-direction: row;
  }

  .hero-content {
    flex: 1;
    margin: 0 50px 0 0;
    text-align: left;
  }

  .hero-image {
    flex: 1;
  }
}
