.news_section {
  font-size: 14px;
  line-height: 1;
}
.news_section a {
  text-decoration: none;
  font-weight: 600;
  color: #000;
  font-weight: 500;
}
.news_section .container {
  /* max-width: 800px;
  min-width: 640px; */
  margin: 0 auto;
}
.news_section main {
  display: flex;
  justify-content: center;
}
.news_section .normal,
.news_section .hover {
  flex: 1;
  padding: 0 25px;
  max-width: 400px;
}
.demo-title {
  color: #666;
  margin: 0 0 15px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.module {
  margin-top: 30px;
  min-width: 50px;
  height: 470px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s linear 0s;
  overflow: hidden;
  position: relative;
}
.module:hover {
  box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
}
.thumbnail {
  position: relative;
  overflow: hidden;
  background: black;
}
.thumbnail img {
  width: 120%;
  transition: all 0.3s;
}
.module:hover .thumbnail img {
  transform: scale(1.1);
  opacity: 0.6;
}

.thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  background: var(--hover_color);
  padding-top: 10px;
  color: var(--primary);
  font-weight: bold;
  border-radius: 100%;
  height: 55px;
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
}
.date div:first-child {
  font-size: 18px;
  line-height: 1.2;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  /* height: 178px; */
  bottom: 0;
  background: #fff;
  padding: 30px;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05);
}
.module:hover .content {
  /* height: 278px; */
  height: fit-content;
}

.content .category {
  position: absolute;
  top: -34px;
  left: 0;
  color: #fff;
  text-transform: uppercase;
  background: var(--hover_color);
  padding: 10px 15px;
  font-weight: bold;
}
.title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 24px;
  font-weight: 700;
}
.sub-title {
  margin: 0;
  padding: 0 0 20px;
  color: var(--hover_color);
  font-size: 20px;
  font-weight: 400;
}
.description {
  color: #666666;
  font-size: 14px;
  line-height: 1.8em;
  height: 0;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  overflow: hidden;
}
.module:hover .description {
  height: 150px;
}
.meta {
  margin: 30px 0 0;
  color: #999999;
}
.news_heading {
  margin-top: 40px;
}

.news_section .container {
  --bs-gutter-x: 0rem;
}

.slider_container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin-bottom: 52px;
}

.news_section .slider {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 30px;
}

@media screen and (max-width: 900px) {
  .news_section main {
    flex-direction: column;
  }

  .news_section .normal,
  .news_section .hover {
    padding: 0;
    margin-left: 20px;
    margin-right: 20px;

    /* width: 340px; */
  }

  .module {
    min-width: auto;
    height: auto;
  }

  .thumbnail img {
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 760px) {
  .news_section .news_card_container {
    display: flex;
    justify-content: center;
  }
}
