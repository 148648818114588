.popular-categories {
  margin-top: 85px;
  margin-bottom: 2em;
  padding-bottom: 8em;
}

.popular-categories .section-heading {
  text-align: center;
  margin-bottom: 80px;
}

.popular-categories .naccs {
  position: relative;
  background-color: #2b2d42;
  border-radius: 7px;
}

.popular-categories .icon {
  display: inline-block;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  text-align: center;
  line-height: 62px;
  color: #8d99af;
  background-color: #fff;
  margin-right: 15px;
}

.popular-categories .icon img {
  max-width: 27px;
}

.popular-categories .naccs .menu div {
  color: #fff;
  font-size: 15px;
  background-color: #8d99af;
  margin-bottom: 1px;
  height: 9em;
  text-align: left;
  padding: 0px 15px;
  line-height: 126px;
  cursor: pointer;
  position: relative;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  display: table-cell;
  vertical-align: middle;
}

.popular-categories .naccs .menu div.active,
.popular-categories .naccs .menu div.active .thumb {
  background-color: #2b2d42;
}

.popular-categories ul.nacc {
  position: relative;
  min-height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  /* transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1); */
}

/* .popular-categories ul.nacc li {
    opacity: 0;
    transform: translateX(-50px);
    position: absolute;
    list-style: none;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  } */

.popular-categories ul.nacc li.active {
  transition-delay: 0.3s;
  position: relative;
  z-index: 2;
  opacity: 1;
  /* transform: translateX(0px); */
}

.popular-categories ul.nacc li {
  width: 100%;
}

.popular-categories .nacc .thumb .left-text {
  margin-left: 30px;
}

.popular-categories .nacc .thumb .left-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 30px;
}

.popular-categories .nacc .thumb .left-text p {
  color: #fff;
  margin-bottom: 30px;
  text-align: justify;
}

.popular-categories .nacc .thumb .right-image {
  padding: 60px;
  max-height: 30em;
  display: inline-flex;
}

.popular-categories .nacc .thumb img {
  border-radius: 7px;
  max-height: 30em;
}

.popular-categories .nacc .thumb .left-text .main-white-button a {
  width: 100%;
  text-align: center;
}

.category-post .naccs .menu div.first-thumb {
  border-bottom-left-radius: 7px;
}

.listing-page .naccs .menu div.first-thumb {
  border-radius: 7px;
}

.left_section {
  display: flex;
  overflow: scroll;
  overflow-x: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 80vh;
}

.left_section::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.left_section {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* scroll-snap-type: x mandatory; */
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
}

/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

.main-white-button a {
  display: inline-block;
  background-color: #fff;
  font-size: 15px;
  font-weight: 400;
  color: #2a2a2a;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 7px;
  letter-spacing: 0.25px;
  transition: all 0.3s;
}

.main-white-button a i {
  margin-right: 10px;
  width: 22px;
  height: 22px;
  background-color: #8d99af;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
}

.main-white-button a:hover {
  background-color: #8d99af;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .left_section {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: auto;
  }
}

@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
