.navbar {
  backdrop-filter: blur(8px);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  box-shadow: 1px 3px 8px 0px;
}

.navbar-logo img {
  height: 70px;
  padding: 10px;
}
.navbar-container {
  display: flex;
  height: 80px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  justify-self: start;
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: auto;
  padding: 0em 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-logo span {
  color: var(--hover_color);
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
  padding: 0px;
}

.nav-item {
  height: 80px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}
.nav-item:hover {
  border-bottom: 3px solid aqua;
}

.nav-links {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  cursor: pointer;
}

.nav-links:hover {
  /* border-bottom: 4px solid var(--hover_color); */
  transition: all 0.2s ease-out;
  padding: 5px 1rem;
}

.fa-bars {
  color: var(--dark);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.demo-btn {
  padding: 0.5rem 2rem;
  border-radius: 10px;
  background-color: transparent;
}
.demo-btn:hover {
  background-color: #3385ff;
  border: 2px solid #3385ff !important;
  color: white !important;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: initial;
    overflow: auto;
  }

  .nav-menu.active {
    background: rgba(0, 43, 76, 0.987);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: white !important;
  }

  .nav-links:hover {
    background-color: var(--primary);
    color: var(--dark);
    border-radius: 0;
  }

  .navbar-logo {
    padding: 0px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: var(--hover_color);
    color: var(--dark);
    transition: 250ms;
  }
  .nav-item{
    margin: 1rem 0px;
  }
}

.nav-btn {
  margin-bottom: 15px;
  margin-right: 15px;
}
