.featured {
  background-color: #f6f8fa;
}
.featured .span {
  font-size: 17px;
  line-height: 20px;
  color: #2d2d2d;
}
.our_two_box {
  box-shadow: #413a39 0px 5px 10px 0px;
  margin: 1em;
}
.our_text_box {
  /* text-align: justify; */
}
.our_img figure {
  margin: 0;
  background-color: #00b1ff;
}
.our_img figure img {
  width: 100%;
}
.awesome {
  padding-bottom: 40px;
  line-height: 38px;
  color: #333334;
  justify-content: unset;
}
.our_text_box p {
  /* padding-bottom: 50px; */
  color: #3c3c3c;
  display: inline-block;
}
.flot_left1 {
  float: left;
  padding: 0;
}
ul.like {
  float: right;
}
ul.like li {
  display: inline-block;
}
ul.like li a {
  display: inline-block;
  color: #3c3c3c;
}
ul.like li img {
  padding: 0px 10px 0px 40px;
}
.our_two_box {
  padding: 20px;
  background-color: snow;
  border-radius: 8px;

  transition-property: box-shadow, transform;
  transition-duration: 350ms;
  transition-timing-function: ease;

  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
}

.our_two_box:hover {
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.2);
  transform: translateY(-8px);
}

.mover {
  animation: move;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes move {
  from {
    transform: translateY(300px);
  }
  to {
    transform: translateY(0px);
  }
}

.copyright {
  padding: 2em;
  font-style: italic;
  right: 2em;
  text-align: end;
}

.copyright p {
  color: white;
}

.copyright a {
  color: white;
}

.titlepage {
  text-align: center;
  padding-bottom: 30px;
}

.titlepage h2 {
  font-size: 40px;
  color: #3e3e3e;
  line-height: 57px;
  font-weight: bold;
  padding: 0;
  padding-bottom: 20px;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1200px) and (max-width: 1500px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
  .awesome {
    padding-bottom: 20px;
    line-height: 25px;
  }
  .our_text_box p {
    padding-bottom: 15px;
    padding-bottom: 15px;
    font-size: 16px;

    line-height: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .our_text_box {
    padding: 20px;
  }
}

@media (max-width: 575px) {
  .pa_wi {
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .featured .laptop__bottom {
    display: none;
  }
}
@media (max-width: 768px) {
  .our_text_box {
    margin-top: 1.4rem;
  }
}

/* Laptop frame section */
.laptop {
  position: relative;
  margin: auto;
  max-width: 80rem;
}
.laptop .laptop__screen {
  position: relative;
  z-index: 1;
  padding: 3%;
  border-radius: 2rem;
  background: #ecf1f7;
  background-image: linear-gradient(to bottom, #333, #111);
  box-shadow: 0 0.1rem 0 #cfcfcf;
  border: 2px solid #ccc;
}
.laptop .laptop__screen img {
  border-radius: 20px;
  display: block;
  max-width: 100%;
  height: auto;
  aspect-ratio: attr(width) / attr(height);
  background: #000;
}
.laptop .laptop__bottom {
  position: relative;
  z-index: 1;
  margin-right: -7%;
  margin-left: -7%;
  height: 0.7rem;
  background: #e9eff5;
  background-image: linear-gradient(
    to right,
    #d2dde9 0%,
    #f9fcff 15%,
    #e5ebf2 40%,
    #e5ebf2 60%,
    #f9fcff 85%,
    #d2dde9 100%
  );
}
.laptop .laptop__bottom::before {
  display: block;
  margin: 0 auto;
  width: 20%;
  height: 0.7rem;
  border-radius: 0 0 0.2rem 0.2rem;
  background: #f6f9fc;
  background-image: linear-gradient(
    to right,
    #c3cfdb 0%,
    #f6f9fc 10%,
    #f6f9fc 90%,
    #c3cfdb 100%
  );
  content: " ";
}
.laptop .laptop__under {
  position: absolute;
  top: 100%;
  left: 25%;
  display: block;
  width: 50%;
  height: 0.5rem;
  background: #e2e8f0;
  background-image: linear-gradient(to bottom, #e2e8f0, #bec7d1);
}
.laptop .laptop__under::after,
.laptop .laptop__under::before {
  position: absolute;
  top: 0%;
  right: 100%;
  bottom: 0;
  display: block;
  width: 50%;
  border-bottom-left-radius: 100%;
  background: inherit;
  content: " ";
}
.laptop .laptop__under::after {
  right: auto;
  left: 100%;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 0;
}
/* .laptop .laptop__shadow {
  position: absolute;
  right: -10%;
  bottom: -2.5rem;
  left: -10%;
  z-index: 0;
  height: 2rem;
  background: radial-gradient(ellipse closest-side, #000, transparent);
  opacity: 0.5;
} */

.btn-access {
  display: flex;
  justify-content: center;
}

.featured {
  padding-bottom: 25px;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.next_icon {
  margin-left: 20px;
}

.prev_icon {
  margin-right: 30px;
}

.slider .next_icon:hover {
  cursor: pointer;
}

.slider .prev_icon:hover {
  cursor: pointer;
}

.slider .period_icon:hover {
  cursor: pointer;
}
