#dropdown-menu,
#dropdown-menu-inside {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  color: inherit;
  background: none;
  font-size: inherit;
  justify-content: center;
}

#dropdown-menu-inside:hover {
  background-color:#00a0b0 !important;
  color: white !important;
}

.dropdown-menu {
  padding: 0px;

}

.dropdown-menu button,
.dropdown-menu .nav-links {
  width: 100%;
  padding: 0.7em;
  justify-content: center;
  color: white !important;
  background-color: #1d374a !important;
  border-bottom: 1px solid gray;
}

.dropdown-menu .nav-links:hover {
  transition: none;
  padding: 0.7em;
  background-color:#00a0b0 !important;
  color: white !important;
}

.dropdown-item {
  text-wrap: wrap;
  background-color: #1d374a !important;
  color: white !important;
  border-bottom: 1px solid gray;
  padding: 10px 5px;
  text-align: center;
}
.dropdown-item:hover{
  background-color: #00a0b0 !important;
  color: white !important;
}

.dropend .dropdown-toggle::after {
  font-size: 1.3rem;
}

@media screen and (max-width: 960px) {
  #dropdown-menu {
    color: white !important;
  }
  .dropdown .dropdown-menu {
    overflow: auto;
    padding-bottom: 1em;
  }
}
