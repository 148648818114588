.titlepage {
  text-align: center;
  padding-bottom: 30px;
}

.titlepage h2 {
  font-size: 40px;
  color: #3e3e3e;
  line-height: 57px;
  font-weight: bold;
  padding: 0;
  padding-bottom: 20px;
}
.partners_container {
  padding-top: 4rem;
  background-color: #f6f8fa;
}

.image-grid {
  text-align: center;
  padding-bottom: 1em;
  margin-left: 4em;
  margin-right: 4em;
}

.image-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.image-row .image {
  margin: 12px;
  flex: 1 1 25%;
  padding: 1em;
}

.image img {
  height: auto;
  max-width: max-content;
}

@media (max-width: 1000px) {
  .image-row .image {
    margin: 12px;
    flex: 1 1 40%;
  }
  .image-grid {
    margin-left: 2em;
    margin-right: 2em;
  }
}

@media (max-width: 450px) {
  .image-row .image {
    margin: 12px;
    flex: 1 1 60%;
  }

  .image-grid {
    margin: 0em;
  }
}
