#projects {
  scroll-margin-top: 2em;
}

.products {
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.demos .col-md-6 {
  padding: 0 !important;
}
.col-md-6 {
  width: 50%;
}
.choice {
  position: relative;
  height: 100%;
  background: #f6f8fa;
  padding: 40px 64px;
  overflow: hidden;
  cursor: default;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  border: 1px solid #eee;
}
.choice .text-link:hover {
  background: var(--hover_color);
  color: #fff;
  border-color: var(--hover_color);
}
.choice img {
  width: 50%;
  display: inline-block;
  margin-right: 5%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  top: 50%;
  position: relative;
}

img {
  max-width: 100%;
  vertical-align: middle;
  border: 0;
}

.choice .text-link {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: -1px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 3px;
  color: #222;
  border: 2px solid #222;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transform: scale(1);
  -webkit-transform: scale(1);
  -webkit-backface-visibility: hidden;
}
.text-link:nth-of-type(1) {
  margin-right: 16px;
}

.choice:hover {
  background: #fefefe;
}

.desc {
  display: inline-block;
  width: 45%;
  float: right;
  position: relative;
}

.btn-sep {
  margin-top: 25%;
}

h6 {
  font-size: 14px;
  letter-spacing: 3px;
  margin-right: -3px;
  margin-bottom: 12px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.text-center h6 {
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
}

p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  cursor: default;
  margin: 0 0 10px;
  color: #888888;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none;
}

.choice {
  padding: 40px 32px;
}

::selection {
  background: var(--hover_color);
  color: #fff;
}
::selection {
  background: var(--hover_color);
  color: #fff;
}

@media (max-width: 767px) {
  .choice {
    height: auto;
    padding: 24px;
  }
  .choice img {
    width: 100%;
    margin-right: 0;
    top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    margin-bottom: 24px;
  }
  .desc {
    top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 1088px) {
  .choice {
    padding: 0px 64px 0 64px;
  }
  .col-md-6 {
    width: 100%;
  }
}

@media (max-width: 1220px) {
  .choice {
    padding: 40px 32px;
  }
}

@media (max-width: 1366px) {
  .choice {
    padding: 40px, 40px;
  }
}

.demos .row {
  align-items: stretch;
}

.project-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: #3c3d41;
  letter-spacing: 1px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.project-title:hover {
  color: #3273f0;
}

.slider-content {
  text-align: center;
  transition: all 5s ease-in-out 0s;
  -webkit-transition: all 5s ease-in-out 0s;
  -moz-transition: all 5s ease-in-out 0s;
  -ms-transition: all 5s ease-in-out 0s;
  -o-transition: all 5s ease-in-out 0s;
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 0px 0px var(--bs-info);
  -moz-box-shadow: 0px 0px 0px 0px var(--bs-info);
  box-shadow: 0px 0px 0px 0px var(--bs-info);
  box-shadow: 0px 0px 0px 0px var(--bs-info);
  margin-bottom: 1em;
}

.slider-content .image-shop-scroll {
  background-position: center 0;
  background-repeat: no-repeat;
  border-radius: 10px;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 100%;
  position: relative;
  width: 100%;
  margin: auto;
  transform: translateY(0%);
  transition: all 5s ease-in-out 0s;
  -webkit-transition: all 5s ease-in-out 0s;
  -moz-transition: all 5s ease-in-out 0s;
  -ms-transition: all 5s ease-in-out 0s;
  -o-transition: all 5s ease-in-out 0s;
}

.slider-content:hover,
.slider-content:focus {
  -webkit-box-shadow: 0px 0px 25px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 25px 0 rgba(0, 0, 0, 0.1);
}

.slider-content:hover .image-shop-scroll {
  background-position: center 100% !important;
  top: 100%;
  bottom: 0px;
  transform: translateY(-100%);
}

.slider-content a {
  padding: 0;
}
